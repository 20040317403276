<template>
    <!-- 中期 -->
    <div class="metaphase">
        <!-- 服务内容 -->
        <div class="content">
            <p class="p2 title">一、服务内容</p>
            <ul>
                <li v-for="item in contentList" :key="item.id"><span class="span2">{{ item.name }}</span>
                <ul v-if="item.children">
                    <li class="liChildren" v-for="i in item.children" :key="i.id">
                    <span class="span2">{{ i.name }}</span>
                    </li>
                </ul>
                </li>
                <!-- <li> <span class="span2">1.2 心理健康讲座、沙龙</span></li>
                <li><span class="span2">1.3 心理危机干预</span></li> -->
            </ul>
        </div>
        <!-- 服务示例 -->
        <div class="example">
            <p class="p2 title">二、服务示例</p>
            <el-table :data="tableData" :span-method="objectSpanMethod" border style="width: 100%; " :header-cell-style="{
                background: '#EEEEEE', color: '#303030', fontWeight: '500'
            }">
                <el-table-column prop="name" label="内容">
                </el-table-column>
                <el-table-column prop="amount" label="具体活动">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            contentList: [
                {
                    id: 1, name: '1. 企业心理健康体系建立', children: [
                        { id: 1, name: '1.1 员工心理评估' }, {
                            id: 2, name: '1.2 心理健康讲座、沙龙'
                        }
                    ]
                },
                { id: 2, name: '2. 企业心理环境优化' },
                { id: 3, name: '3. 内部EAP人才培养' },
                {
                    id: 4, name: '4. 危机干预体系扶持', children: [
                        { id: 1, name: '4.1 心理危机干预' }, {
                            id: 2, name: '4.2 企业内部危机干预技能培训'
                        }
                    ]
                },
                { id: 5, name: '5. 心理健康医疗绿色通道' }
            ],
            tableData: [{
                id: 1,
                name: '1.心理评估',
                amount: '心理测量 1：员工心理状态评估，如 SCL-90、BDI、BAI等，有助于建立员工心理档案，动态监测员工心理健康状况'
            }, {
                id: 2,
                name: '1.心理评估',
                amount: '心理测量2：员工个性及能力评估：霍兰德职业倾向量表，16PF、威廉森创造力问卷等，有助于了解员工个性及能力，提供企业人才选拔的侧面材料'
            }, {
                id: 3,
                name: '1.心理评估',
                amount: '精神科医生面谈：针对存在心理健康问题的员工，开展 1v1面谈服务。'
            }, {
                id: 4,
                name: '2.员工心理健康热线',
                amount: '通过视频、电话热线等方式进行简单的心理支持和建议与辅导。'
            }, {
                id: 5,
                name: '3.心理健康讲座辅导',
                amount: '有针对性的心理健康讲座与辅导项目， 内容如前述，1 月一次，每次 1-1.5 小时'
            }, {
                id: 6,
                name: '4.心理门诊与咨询',
                amount: '对于有需求的员工，提供心理门诊绿色通道，第一时间满足员工的心理疾病的医疗需求。'
            }, {
                id: 7,
                name: '5.心理危机干预体系建立',
                amount: '帮助单位组建“心理危机处置队伍”和机制，配合单位内部管理工作，提供心理支持和帮助，并对危机事件处理进行后续效果跟进。'
            }, {
                id: 8,
                name: '6.出具报告与建议',
                amount: '出具企业员工心理健康报告及建议'
            }]
        }
    },
    methods: {
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (rowIndex === 0) {
                    return {
                        rowspan: 3,
                        colspan: 1
                    };
                } else if ((rowIndex === 1 && columnIndex === 0) || (rowIndex === 2 && columnIndex === 0)) {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.metaphase {
    .content{
        ul{
            list-style: none;
            .liChildren{
                span{
                    color: var(--hover-Color);
                }
            }
            // li{
            //     &:hover{
            //         color: var(--memoFont-color);
            //         text-decoration: none;
            //         font-weight: 500;
            //     }
            // }
        }
        .liChildren{
            &:hover{
                    color: var(--hover-Color);
                    text-decoration: underline;
                    font-weight: 600;
                    cursor: pointer;
                }
        }
    }
}
</style>