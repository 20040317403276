<template>
    <!-- 短期 -->
    <div class="shortIndex">
        <!-- 服务内容 -->
        <div class="content">
            <p class="p2 title">一、服务内容</p>
            <ul>
                <li><span class="span2" @click="toAssessment('assessment')">1.1 员工心理评估</span></li>
                <li> <span class="span2" @click="toAssessment('lecture')">1.2 心理健康讲座、沙龙</span></li>
                <li><span class="span2">1.3 心理危机干预</span></li>
            </ul>
        </div>
        <!-- 服务示例 -->
        <div class="example">
            <p class="p2 title">二、服务示例(以一周为例)</p>
            <el-table :data="tableData" :span-method="arraySpanMethod" border style="width: 100%; " :header-cell-style="{
                background: '#EEEEEE', color: '#303030', fontWeight: '500'
            }">
                <el-table-column prop="time" label="时间" >
                </el-table-column>
                <el-table-column prop="name" label="内容" >
                </el-table-column>
                <el-table-column prop="amount" label="具体活动">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [{
                id: 1,
                time: 'D0',
                name: '1.心理测评筛查',
                amount: '通过评估系统对客户心理状况及心理能力开展测评，并对客户需求进行分层，确定客户心理面谈服务优先级'
            }, {
                id: 2,
                time: 'D1-3',
                name: '2.心理面谈',
                amount: '根据面谈优先级进行初次面谈'
            }, {
                id: 3,
                time: 'D4',
                name: '3.个别咨询',
                amount: '对需要开展心理咨询和治疗服务的客户开展个别咨询'
            }, {
                id: 4,
                time: 'D5',
                name: '4.团体心理讲座',
                amount: '情绪/压力管理讲座'
            }, {
                id: 5,
                time: 'D5',
                name: '5.精神科医师咨询',
                amount: '对于比较严重的客户，有华西专家开展咨询工作。 提出医疗建议（包括量表评分、睡眠筛查以及面谈评估有较严重问题的客户）'
            }, {
                id: 6,
                time: 'D6-7',
                name: '6.个别咨询',
                amount: '对需要开展心理咨询和治疗服务的客户开展个别咨询'
            }, {
                id: 7,
                time: 'D6-7',
                name: '7.出具报告与建议',
                amount: '出具企业员工心理健康报告及建议'
            }, {
                id: 8,
                time: '后期服务',
                name: '',
                amount: '通过评估系统对客户心理状况及心理能力开展测评，动态监测客户心理状态(建议每 1-3 月进行1 次)'
            }]
        }
    },
    methods: {
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            // 合并行
            if (columnIndex === 0) {
                if (rowIndex === 3 || rowIndex === 5) {
                    return {
                        rowspan: 2,
                        colspan: 1
                    };
                } else if ((rowIndex === 4 && columnIndex === 0) || (rowIndex === 6 && columnIndex === 0)) {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }

                // if (rowIndex === 7) {
                //     return {
                //         rowspan: 1,
                //         colspan: 2
                //     };
                // } 
                // else if(rowIndex === 7 && columnIndex === 1) {
                //     return {
                //         rowspan: 0,
                //         colspan: 0
                //     };
                // }

            }
            // 合并列
            if (rowIndex === 7) {
                if (columnIndex === 0) {
                    return [1, 2];
                } else if (columnIndex === 1) {
                    return [0, 0];
                }
            }
        },

        // 跳转
        toAssessment(val) {
            this.$router.push({
                path: `/enterprise/${val}`,
                query: {
                    index: 1,
                    id: this.$route.query.id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.shortIndex {
    .content ul li {
        span {
            color: var(--hover-Color);
            // font-weight: 600;
        }

        // &:last-child {
        //     span {
        //         // cursor: alias;
        //         color: var(--memoFont-color);
        //         font-weight: 500;
        //         // text-decoration: none;
        //     }
        // }

    }

    .content ul li:hover {
        cursor: pointer;
        font-weight: 600;
        color: var(--hover-Color);
        text-decoration: underline;

        // &:last-child {
        //     span {
        //         cursor:auto;
        //         color: var(--memoFont-color);
        //         font-weight: 500;

        //     }
        //     text-decoration: none;
        // }
    }
}
</style>