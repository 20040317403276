<template>
    <div class="index">
        <!-- 服务方案 -->
        
        <div>

            <ShortVue v-if="id === 1 || id === '1'"></ShortVue>
            <MetaphaseVue v-else-if="id === 2 || id === '2'"></MetaphaseVue>
            <CharacteristicVue v-else></CharacteristicVue>
        </div>
        <div class="btn1">
            <span class="span2" @click="toIndex">返回服务方案</span>
        </div>
    </div>
</template>

<script>
import ShortVue from './short/Index.vue'
import MetaphaseVue from './metaphase/Index.vue'
import CharacteristicVue from './characteristic/Index.vue'
export default {
    data() {
        return{
            id: 1
        }
    },
    components: { ShortVue, MetaphaseVue, CharacteristicVue },
    methods: {
        toIndex(){
            this.$router.push('/enterprise/index')
        }
    },
    created() {
        if(this.$route.query.id) {
            this.id = this.$route.query.id
        }
        // console.log()
    }
}
</script>

<style lang="scss" scoped>
@import url('../../assets/css/programme.scss');
.index {
    padding: 30px 0px;
}
</style>