<template>
    <!-- 特色 -->
    <div class="characteristic">
         <!-- 服务内容 -->
         <div class="content">
            <!-- <p class="p2 title">一、服务内容</p> -->
            <ul>
                <li><span class="span2">睡眠专题健康管理服务</span></li>
                <li> <span class="span2">戒烟专题健康管理服务</span></li>
                <li><span class="span2">休学子女援助计划</span></li>
                <li><span class="span2">孕产妇心理健康管理服务</span></li>
                <li><span class="span2">校园心理健康管理服务</span></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.characteristic{
    ul{
        li{
            &:hover{
                
            }
        }
    }
}

</style>